import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const DownloadAppKr = lazy(() => import('./pages/download-app-kr'))
const DownloadAppEN = lazy(() => import('./pages/download-app-en'))
export default () => {
  return (
    <BrowserRouter basename="/lp-btcc-rocks">
      <Switch>
        <Route exact path="/" component={Index}/>
        <Route  path="/download-app-kr" component={DownloadAppKr} />
        <Route  path="/download-app-en" component={DownloadAppEN} />
      </Switch>
    </BrowserRouter>
  );
};
